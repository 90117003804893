import React, { useState } from "react";
import logo from "../../Assets/img/logo.png";
import emailIcon from "../../Assets/img/icon/mail-outline.svg";
import passwordIcon from "../../Assets/img/icon/password.svg";
import { useNavigate } from "react-router-dom";

function Login() {

  let navigate = useNavigate();

  const [login, setLogin] = useState({
      email: "",
      password: "",
  });


  const userLogin = (e) => {
      setLogin({
          ...login,
          [e.target.name]: e.target.value,
      });
  };


  const url = `https://api.fhirgo.com:3000/api/admin/login`;

  const onSubmitLoginCredentials = (e) => {
      navigate("/home");
      e.preventDefault();

      // axios
      //     .post(url, login)
      //     .then((res) => {
      //         console.log(res)
            


      //     })
      //     .catch((error) => {
      //         alert(error);
      //         // setError(res.message);
      //     });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-md-12 col-12 col-lg-6 d-none d-lg-flex">
          <div className="login-bg"></div>
        </div>
        <div className="col-md-12 col-12 col-lg-6">
          <div className="login-box">
            <div className="container h-100">
              <div className="row d-flex justify-content-center h-100 align-items-center">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="l-box">
                    <div className="text-center">
                      <img src={logo} className="img-fluid mb-4" alt="logo" />
                    </div>
                    <p className="semibold ft-32 mb-2 text-dark">Login</p>
                    <p className="mb-4 mb-lg-5">
                      Welcome back! Please enter your details.
                    </p>
                    <form>
                      <div className="form-group position-relative">
                        <label className="medium text-dark mb-2">Email</label>
                        <img
                          src={emailIcon}
                          className="img-fluid login-fc-icon"
                          alt="icon"
                        />
                        <input
                          type="email"
                          name="email"
                          value={login.email}
                          onChange={(e) => userLogin(e)}
                          placeholder="Enter your email"
                          className="login-form-control"
                        />
                      </div>
                      <div className="form-group position-relative">
                        <label className="medium text-dark mb-2">
                          Password
                        </label>
                        <img
                          src={passwordIcon}
                          className="img-fluid login-fc-icon p-icon"
                          alt="icon"
                        />
                        <input
                          type="text"
                          name="password"
                          value={login.password}
                          onChange={(e) => userLogin(e)}
                          placeholder="Password"
                          className="login-form-control"
                        />
                      </div>
                      <div className="form-group d-flex justify-content-between m-ft-14">
                        <label className="container1">
                          Remember for 30 days
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <p className="text-theme">Forgot Password</p>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-theme w-100" onClick={onSubmitLoginCredentials} type="submit">Sign in</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
