import React, { useEffect, useState } from "react";
import Select from "react-select";
import searchIcon from "../../Assets/img/icon/search.svg";
import viewIcon from "../../Assets/img/icon/view.svg";
import SearchParams from "../sub-components/SearchParams";
import updateIcon from "../../Assets/img/icon/update.svg";
import { getCall } from "../Utils/Api";
import CONSTANTS from "../Utils/Constants";

function Queries() {
  const [resource, setresource] = useState([]);
  const [resourceValue, setResourceValue] = useState("");
  const [resourceSelected, setResourceSelected] = useState("");
  const [sortData, setSearchparam] = useState([]);
  const [inludes, setInludes] = useState([]);
  const [encodingList, setEncodingList] = useState([]);
  const [encoding, setEncoding] = useState({});
  const [prettyList, setPrettyList] = useState([]);
  const [pretty, setPretty] = useState({});
  const [summaryList, setSummaryList] = useState([]);
  const [summary, setSummary] = useState({});
  const [searchArray, setSearchArray] = useState([]);
  const [value, setvalue] = useState();
  const [datatype, setdatatype] = useState("");
  const [sortSelected, setSortSelected] = useState([]);
  const [resultSet, setResultSet] = useState([]);
  const [options, setOptions] = useState([]);
  const [readResult, setReadResult] = useState();

  const reset = function () {
    var allchecked = document.querySelectorAll("[name='search_include']");
    for (let i = 0; i < allchecked.length; i++) {
      const el = allchecked[i];
      el.checked = false;
    }
  };

  useEffect(() => {
    getdata();
    setEncodingList([...CONSTANTS.OPTIONS.ENCODING]);
    setEncoding({
      ...CONSTANTS.OPTIONS.ENCODING.filter((x) => x.label === "Default")[0],
    });
    setPrettyList([...CONSTANTS.OPTIONS.PRETTY]);
    setPretty({
      ...CONSTANTS.OPTIONS.PRETTY.filter((x) => x.label === "Default")[0],
    });
    setSummaryList([...CONSTANTS.OPTIONS.SUMMARY]);
    setSummary({
      ...CONSTANTS.OPTIONS.SUMMARY.filter((x) => x.label === "Default")[0],
    });
  }, []);

  const getdata = async () => {
    const data = await getCall("metadata");
    if (!data) return;
    setresource(
      data.rest[0].resource.filter((x) => x.extension && x.extension.length > 0)
    );
    setSearchparam(data.rest[0].resource[0].searchParam);
    setInludes(data.rest[0].resource[0].searchInclude);
    reset();
    setResourceSelected(data.rest[0].resource[0].type);
  };

  const observation = (e) => {
    setvalue(e.value);
    setResourceValue(e.value);
    const SearchParam = e.value;
    const result = resource.find(({ type }) => type === SearchParam);
    setSearchparam(result.searchParam);
    setInludes(result.searchInclude);
    setdatatype(result.searchParam);
    reset();
    setResourceSelected(result.type);
    setSearchArray([]);
  };

  const onSearchClick = async (e) => {
    var allchecked = document.querySelectorAll(
      "[name='search_include']:checked"
    );
    var queryParams = searchArray.map((x) => x.selection).join("&");
    queryParams +=
      "&_include=" + [...allchecked].map((x) => x.value).join("&_include=");
    var result = await getCall(
      resourceSelected +
        "?" +
        (options ?? "") +
        "&" +
        (queryParams ?? "") +
        (sortSelected ? "&_sort=" + sortSelected : "")
    );
    if (result) setResultSet(result.entry);
  };

  const onOptionChange = (e) => {
    onOptionChange(
      `&_format=${encoding.value}&_pretty=${pretty.value}&_summary=${summary.value}`
    );
  };

  const onReadClick = function (id) {
    getCall(id).then((x) => {
      setReadResult(x);
    });
  };

  return (
    <>
      {/* <!-- content --> */}
      <div className="content">
        {/* <!-- Queries --> */}
        <div className="row mb-3 mb-lg-4">
          <div className="col-12">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">Queries</div>
          </div>
          <div className="col-12">
            <div className="box001">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="form-t-group">
                    <Select
                      className="basic-single w-100"
                      type="button"
                      placeholder={"Supported Resource Type"}
                      classNamePrefix="select"
                      onChange={observation}
                      name="color"
                      options={resource?.map((data) => {
                        return {
                          value: data.type,
                          label:
                            data.type +
                            " (" +
                            data.extension[0].valueDecimal +
                            ")",
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-t-group">
                    <Select
                      className="basic-single w-100"
                      type="button"
                      placeholder={"Select Parameter"}
                      classNamePrefix="select"
                      onChange={observation}
                      name="color"
                      options={resource?.map((data) => {
                        return {
                          value: data.type,
                          label:
                            data.type +
                            " (" +
                            data.extension[0].valueDecimal +
                            ")",
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-9 col-lg-4">
                  <div className="form-m-group">
                    <input
                      type="text"
                      placeholder="Enter Resource Indentifire to Query"
                      className="form-control"
                      name=""
                    />
                  </div>
                </div>
                <div className="col-md-3 col-lg-2">
                  <button className="w-100 btn btn-red" onClick={onSearchClick}>
                    <img src={searchIcon} className="img-fluid" alt="icon" />{" "}
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Searched Data --> */}
        <div className="row">
          <div className="col-12">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">
              Searched Data
            </div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-md-12">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-graphql-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-graphql"
                  type="button"
                  role="tab"
                  aria-controls="pills-graphql"
                  aria-selected="true"
                >
                  GraphQL Query
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-results-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-results"
                  type="button"
                  role="tab"
                  aria-controls="pills-results"
                  aria-selected="false"
                >
                  Query Results
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-graphql"
                role="tabpanel"
                aria-labelledby="pills-graphql-tab"
              >
                <div className="row align-items-center mt-2">
                  <div className="col-md-8 col-lg-9">
                    <textarea className="form-control" rows="10"></textarea>
                  </div>
                  <div className="col-md-4 col-lg-3 mt-3 mt-md-0">
                    <button className="0 btn btn-red mb-2 me-2 btn-small">
                      <img src={searchIcon} className="img-fluid" alt="icon" />{" "}
                      Query w/GET
                    </button>
                    <br></br>
                    <button className=" btn btn-red mb-2 me-2 btn-small">
                      <img src={searchIcon} className="img-fluid" alt="icon" />{" "}
                      Query w/POST GQL
                    </button>
                    <br></br>
                    <button className=" btn btn-red mb-2 me-2 btn-small">
                      <img src={searchIcon} className="img-fluid" alt="icon" />{" "}
                      Query w/POST JSON
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-results"
                role="tabpanel"
                aria-labelledby="pills-results-tab"
              >
                <div className="row g-4">
                  {resultSet.length ? (
                    resultSet.map((c, i) => {
                      const id = `${c.resource.resourceType}/${c.resource.id}/_history/1`;
                      const resourceid = `${c.resource.resourceType}/${c.resource.id}`;
                      return (
                        <div className="col-md-6 col-lg-3">
                          <div className="box001">
                            <p className="text-dark medium text-break mb-3">
                              {`${c.resource.resourceType}/${c.resource.id}/_history/1`}
                            </p>
                            <p className="small">
                              {new Date(
                                c.resource.meta.lastUpdated
                              ).toLocaleString()}
                            </p>
                            <div className="mt-3 d-flex">
                              <p
                                className="me-3 text-primary h-op-8"
                                data-bs-toggle="modal"
                                data-bs-target="#viewresulModal"
                                onClick={() => onReadClick(resourceid)}
                              >
                                <img
                                  src={viewIcon}
                                  className="img-fluid mm-2"
                                  alt="icon"
                                />{" "}
                                View Result
                              </p>
                              <p
                                className="me-3 text-success h-op-8"
                                data-bs-toggle="modal"
                                data-bs-target="#narrativelModal"
                                onClick={() => onReadClick(id)}
                              >
                                <img
                                  src={updateIcon}
                                  className="img-fluid mm-2"
                                  alt="icon"
                                />{" "}
                                Narrative
                              </p>
                              {/* <a
                                className="text-success h-op-8"
                                data-bs-toggle="popover"
                                data-bs-trigger="focus"
                                data-bs-html="true"
                                title="Narrative Details"
                                data-bs-content="<span className='bold'>ID:</span> ActivityDefinition/serum-dengue-virus-igm <br> 
                               <span className='bold'>Status:</span> draft <br>
                               <span className='bold'>Description:</span> Order Serum Dengue Virus IgM"
                              >
                                <img
                                  src={updateIcon}
                                  className="img-fluid mm-2"
                                  alt="icon"
                                />{" "}
                                Narrative
                              </a> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="text-center">No Record Found</h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- viewresulModal --> */}
      <div
        className="modal fade"
        id="viewresulModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Result
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  
                  <article>
                    <div>
                      {readResult && (
                          <pre>{JSON.stringify(readResult, null, 2)}</pre>
                      )}
                      {!readResult && (
                        <div className="text-center">NO DATA FOUND</div>
                      )}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          {/* <!-- NarrativelModal --> */}
          <div
        className="modal fade"
        id="narrativelModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
               Narrative Result
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <article>
                    <div>
                      {readResult?.text?.div && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: readResult?.text?.div,
                          }}
                        ></div>
                      )}
                      {!readResult?.text?.div && (
                        <div className="text-center">NO DATA FOUND</div>
                      )}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Queries;
