import React, { useState } from "react";
import { Link } from "react-router-dom";
import menuIcon from "../../Assets/img/icon/menu.svg";
import logoWhite from "../../Assets/img/logo-white.png";

function NavBar() {
  const [nav, setNav] = useState(false);
  return (
    <>
      {/* <!-- header --> */}
      <div className="header">
        <div className="navbar">
          <div className="d-flex justify-content-between w-100">
            <div>
              <img src={logoWhite} className="img-fluid" alt="logo" />
            </div>
            <ul className="list-unstyled mb-0 d-inline-flex align-items-center">
              <li className=" dropdown progile-dropdown list-inline-item me-4 position-relative">
                <p
                  className=" dropdown-toggle dg-none text-muted pr-0"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="currentColor"
                    className="bi bi-bell text-dark mt-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                  </svg>
                  <span className="dot"></span>
                </p>
                <div
                  className="dropdown-menu dropdown-menu-end notify-dropdown"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <ul className="list-unstyled mb-0">
                    <li>
                      Package is zipped and uploaded{" "}
                      <p className="mt-1 mb-0 op-8">
                        <small>2:00 pm</small>
                      </p>
                    </li>
                    <li>
                      Package is zipped and uploaded{" "}
                      <p className="mt-1 mb-0 op-8">
                        <small>2:00 pm</small>
                      </p>
                    </li>
                    <li>
                      Package is zipped and uploaded{" "}
                      <p className="mt-1 mb-0 op-8">
                        <small>2:00 pm</small>
                      </p>
                    </li>
                    <li>
                      Package is zipped and uploaded{" "}
                      <p className="mt-1 mb-0 op-8">
                        <small>2:00 pm</small>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className=" dropdown profile-dropdown  list-inline-item">
                <a
                  className=" dropdown-toggle text-dark pr-0"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-none d-md-inline-block">Hello, </span>Scott
                </a>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <p className="dropdown-item" href="profile.html">
                    Profile
                  </p>
                  <p className="dropdown-item" href="settings.html">
                    Settings
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="all-nav">
          <div
            className="semibold me-4 ft-20 d-inline-flex align-items-center cr"
            onClick={() => setNav(true)}
          >
            <img src={menuIcon} className="img-fluid me-2" alt="icon" />
            All
          </div>
          <ul className="mb-0 list-unstyled list-inline d-inline-flex m-hide align-items-center">
            <li className="list-inline-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/queries">Queries</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/crud-operations">Crud Operations</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/import">Import</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/export">Export</Link>
            </li>
          </ul>
        </div>
        <div className={nav ? "overlay sidenav navwidth" : "sidenav"}>
          <p className="closebtn text-white" onClick={() => setNav(false)}>
            &times;
          </p>
          <Link to="/">Upcoming Release</Link>
          <Link to="/">System Health</Link>
          <Link to="/">Notification History</Link>
          <Link to="/">What is New?</Link>
          <Link to="/">HL7 Standards</Link>
          <Link to="/">Dashboard</Link>
          <Link to="/queries">Queries</Link>
          <Link to="/crud-operations">Crud Operations</Link>
          <Link to="/">Import</Link>
          <Link to="/">Export</Link>
        </div>
      </div>
    </>
  );
}

export default NavBar;
