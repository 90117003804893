import Login from "./Component/Login/Login";
import { Routes, Route } from "react-router-dom";
import "./CSS/Coustom.css";
import Home from "./Component/Home/Home";
import Queries from "./Component/Queries/Queries";
import CrudOperations from "./Component/CrudOperations/CrudOperations";
import Dashboard from "./Component/Dashboard/Dashboard";
import Import from "./Component/Import/Import";
import Export from "./Component/Export/Export";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Home />} />
        <Route path="/queries" element={<Queries />} />
        <Route path="/crud-operations" element={<CrudOperations />} />
        <Route path="/import" element={<Import />} />
        <Route path="/export" element={<Export />} />
      </Route>
    </Routes>
  );
}

export default App;
