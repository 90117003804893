import React, { useEffect, useState } from "react";
import Select from "react-select";
import plusIcon from "../../Assets/img/icon/plus.svg";
import searchIcon from "../../Assets/img/icon/search.svg";
import viewIcon from "../../Assets/img/icon/view.svg";
import updateIcon from "../../Assets/img/icon/update.svg";
import { getCall } from "../Utils/Api";
import CONSTANTS from "../Utils/Constants";
import SearchParams from "../sub-components/SearchParams";

function Home() {
  const [resource, setresource] = useState([]);
  const [resourceSelected, setResourceSelected] = useState("");
  const [sortData, setSearchparam] = useState([]);
  const [inludes, setInludes] = useState([]);
  const [encodingList, setEncodingList] = useState([]);
  const [options, setOptions] = useState([])
  const [encoding, setEncoding] = useState({});
  const [prettyList, setPrettyList] = useState([]);
  const [pretty, setPretty] = useState({});
  const [summaryList, setSummaryList] = useState([]);
  const [summary, setSummary] = useState({});
  const [searchArray, setSearchArray] = useState([]);
  const [value, setvalue] = useState();
  const [datatype, setdatatype] = useState("");
  const [sortSelected, setSortSelected] = useState("");
  const [resultSet, setResultSet] = useState([]);
  const [readResult, setReadResult] = useState();
  const [searchParam, setSearch] = useState("");


  const reset = function () {
    var allchecked = document.querySelectorAll("[name='search_include']");
    for (let i = 0; i < allchecked.length; i++) {
      const el = allchecked[i];
      el.checked = false;
    }
  };

  useEffect(() => {
    getdata();
    setEncodingList([...CONSTANTS.OPTIONS.ENCODING]);
    setEncoding({
      ...CONSTANTS.OPTIONS.ENCODING.filter((x) => x.label === "Default")[0].value,
    });
    setPrettyList([...CONSTANTS.OPTIONS.PRETTY]);
    setPretty({
      ...CONSTANTS.OPTIONS.PRETTY.filter((x) => x.label === "Default")[0].value,
    });
    setSummaryList([...CONSTANTS.OPTIONS.SUMMARY]);
    setSummary({
      ...CONSTANTS.OPTIONS.SUMMARY.filter((x) => x.label === "Default")[0].value,
    });
  }, []);

  const getdata = async () => {
    const data = await getCall("metadata");
    if (!data) return;
    setresource(
      data.rest[0].resource.filter((x) => x.extension && x.extension.length > 0)
    );
    setSearchparam(data.rest[0].resource[0].searchParam);
    setInludes(data.rest[0].resource[0].searchInclude);
    reset();
    setResourceSelected(data.rest[0].resource[0].type);
  };

  const observation = (e) => {
    setvalue(e.value);
    const SearchParam = e.value;
    const result = resource.find(({ type }) => type === SearchParam);
    setSearchparam(result.searchParam);
    setInludes(result.searchInclude);
    setdatatype(result.searchParam);
    reset();
    setResourceSelected(result.type);
    setSearchArray([]);
  };

  const onSearchClick = async (e) => {
    // console.log(searchArray);
    // var queryParams = searchArray.map((x) => x.selection).join("&");
    // var result = await getCall(
    //   resourceSelected + "/_search?" + searchParam + (queryParams ?? "")
    //   // (sortSelected ? "&_sort=" + sortSelected : "")
    // );
    var allchecked = document.querySelectorAll("[name='search_include']:checked");
    var queryParams = searchArray.map(x => x.selection).join("&");
    queryParams+= "&_include="+[...allchecked].map(x=>x.value).join("&_include=");
    let opts = [];
    if(encoding) opts.push("_format="+encoding)
    if(pretty) opts.push("_pretty="+pretty)
    if(summary) opts.push("_summary="+summary)
    var result = await getCall(resourceSelected + "?" + (opts.length>0 ? opts.join("&")+"&":"")+(queryParams ?? "")+(sortSelected?"&_sort="+sortSelected:"") );
    if (result) setResultSet(result.entry);
  };

  const onOptionChange = (e) => {
    setOptions(
      `&_format=${encoding}&_pretty=${pretty}&_summary=${summary}`
    );
  };

  const encodingChange = (e) => {
    setEncoding(e.value);
    onOptionChange();
  };

  const prettyChange = (e) => {
    setPretty(e.value);
    onOptionChange();
  };
  const summaryChange = (e) => {
    setSummary(e.value);
    onOptionChange();
  };

  const onReadClick = function (id) {
    getCall(id).then((x) => {
      setReadResult(x);
    });
  };

  return (
    <>
      {/* <!-- content --> */}
      <div className="content">
        {/* <!-- Dashboard --> */}
        <div className="row mb-3 mb-lg-4">
          <div className="col-12">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">Dashboard</div>
          </div>
          <div className="col-12">
            <div className="box001">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <Select
                      className="basic-single w-100"
                      type="button"
                      placeholder={"Select Rescoures"}
                      classNamePrefix="select"
                      onChange={observation}
                      name="color"
                      options={resource?.map((data) => {
                        return {
                          value: data.type,
                          label:
                            data.type +
                            " (" +
                            data.extension[0].valueDecimal +
                            ")",
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <Select
                      className="basic-single w-100"
                      type="button"
                      placeholder={"Select Encoding"}
                      classNamePrefix="select"
                      onChange={encodingChange}
                      name="color"
                      options={encodingList?.map((data) => {
                        return {
                          value: data.label,
                          label: data.label,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <Select
                      className="basic-single w-100"
                      type="button"
                      placeholder={"Select Pretty"}
                      classNamePrefix="select"
                      onChange={prettyChange}
                      name="color"
                      options={prettyList?.map((data) => {
                        return {
                          value: data.label,
                          label: data.label,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <Select
                      className="basic-single w-100"
                      type="button"
                      placeholder={"Select summary"}
                      classNamePrefix="select"
                      onChange={summaryChange}
                      name="color"
                      options={summaryList?.map((data) => {
                        return {
                          value: data.label,
                          label: data.label,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="col-12 form-group">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-md-6">
                      <div className="text-theme mb-2 mb-md-0">
                        <img
                          src={plusIcon}
                          className="img-fluid me-2"
                          alt="icon"
                          onClick={() => {
                            setSearchArray([
                              ...searchArray,
                              { sortData: sortData },
                            ]);
                          }}
                        />{" "}
                        Add Param
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-2">
                      <Select
                        className="basic-single w-100"
                        type="button"
                        placeholder={"Filter"}
                        classNamePrefix="select"
                        name="color"
                        options={CONSTANTS.SORT?.map((data) => {
                          return {
                            value: data,
                            label: data,
                          };
                        })}
                        onChange={e=>setSortSelected(e.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-7 col-md-8 col-lg-10 form-m-group">
                      {
                        searchArray.map((x) => {
                          return (
                            <SearchParams
                              data={x.sortData}
                              sort={sortSelected}
                              setSearch={setSearch}
                              onValueChanged={(e) => {                                
                                x.selection = e;
                                setSearchArray(searchArray);
                              }}
                            />
                          );
                        })
                        // sortData && sortData.length > 0 ? <SearchParams onValueChanged={Selection} data={sortData} ></SearchParams> : <></>
                      }
                    </div>
                    <div className="col-sm-5 col-md-4 col-lg-2 d-flex align-items-end">
                      <button
                        className="w-100 btn btn-red"
                        onClick={onSearchClick}
                      >
                        <img
                          src={searchIcon}
                          className="img-fluid"
                          alt="icon"
                        />{" "}
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Searched Data --> */}
        <div className="row">
          <div className="col-12">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">
              Searched Data
            </div>
          </div>
        </div>
        <div className="row g-4">
          {resultSet.length ? (
            resultSet.map((c, i) => {
              const id = `${c.resource.resourceType}/${c.resource.id}/_history/1`;
              const resourceid = `${c.resource.resourceType}/${c.resource.id}`;
              return (
                <div className="col-md-6 col-lg-3">
                  <div className="box001">
                    <p className="text-dark medium text-break mb-3">
                      {`${c.resource.resourceType}/${c.resource.id}/_history/1`}
                    </p>
                    <p className="small">
                      {new Date(c.resource.meta.lastUpdated).toLocaleString()}
                    </p>
                    <div className="mt-3 d-flex">
                      <p
                        className="me-3 text-primary h-op-8"
                        data-bs-toggle="modal"
                        data-bs-target="#viewresulModal"
                        onClick={() => onReadClick(resourceid)}
                      >
                        <img
                          src={viewIcon}
                          className="img-fluid mm-2"
                          alt="icon"
                        />{" "}
                        View Result
                      </p>
                      <p
                                className="me-3 text-success h-op-8"
                                data-bs-toggle="modal"
                                data-bs-target="#narrativelModal"
                                onClick={() => onReadClick(id)}
                              >
                                <img
                                  src={updateIcon}
                                  className="img-fluid mm-2"
                                  alt="icon"
                                />{" "}
                                Narrative
                              </p>
                      {/* <a
                        className="text-success h-op-8"
                        data-bs-toggle="popover"
                        data-bs-trigger="focus"
                        data-bs-html="true"
                        title="Narrative Details"
                        data-bs-content="<span className='bold'>ID:</span> ActivityDefinition/serum-dengue-virus-igm <br> 
                               <span className='bold'>Status:</span> draft <br>
                               <span className='bold'>Description:</span> Order Serum Dengue Virus IgM"
                      >
                        <img
                          src={updateIcon}
                          className="img-fluid mm-2"
                          alt="icon"
                        />{" "}
                        Narrative
                      </a> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h3 className="text-center">No Record Found</h3>
          )}

          {/* <div className="row mt-4">
            <div className="col-12 text-center bold text-theme ft-18">
              <p className="h-op-8">Load More...</p>
            </div>
          </div> */}
        </div>
      </div>
      {/* <!-- viewresulModal --> */}
      <div
        className="modal fade"
        id="viewresulModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Result
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <article>
                    <div>
                      {readResult && (
                       <pre>{JSON.stringify(readResult, null, 2)}</pre>
                      )}
                      {!readResult && (
                        <div className="text-center">NO DATA FOUND</div>
                      )}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* <!-- NarrativelModal --> */}
        <div
        className="modal fade"
        id="narrativelModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
               Narrative Result
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <article>
                    <div>
                      {readResult?.text?.div && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: readResult?.text?.div,
                          }}
                        ></div>
                      )}
                      {!readResult?.text?.div && (
                        <div className="text-center">NO DATA FOUND</div>
                      )}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- narrativeModal --> */}
      <div
        className="modal fade"
        id="narrativeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Narrative Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <p className="mb-2 bold text-dark">Id:</p>
                </div>
                <div className="col-md-8 col-lg-9">
                  <p className="text-break">
                    ActivityDefinition/serum-dengue-virus-igm
                  </p>
                </div>
                <div className="w-100"></div>
                <div className="col-md-4 col-lg-3">
                  <p className="mb-2 bold text-dark">Status:</p>
                </div>
                <div className="col-md-8 col-lg-9">
                  <p className="text-break">draft</p>
                </div>
                <div className="w-100"></div>
                <div className="col-md-4 col-lg-3">
                  <p className="mb-2 bold text-dark">Description:</p>
                </div>
                <div className="col-md-8 col-lg-9">
                  <p className="text-break">Order Serum Dengue Virus IgM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
