import React, { useEffect, useState } from "react";
import Select from "react-select";
import bookIcon from "../../Assets/img/icon/book.svg";
import shareIcon from "../../Assets/img/icon/share-3798.svg";
import delteIcon from "../../Assets/img/icon/delete-10400.svg";
import pencilIcon from "../../Assets/img/icon/pencil-938.svg";
import historyIcon from "../../Assets/img/icon/history-7613.svg";
import viewIcon from "../../Assets/img/icon/view.svg";
import updateIcon from "../../Assets/img/icon/update.svg";
import plusIcon from "../../Assets/img/icon/plus-3107.svg";
import checkIcon from "../../Assets/img/icon/check-7047.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCall, getCall, postCall, putCall } from "../Utils/Api";

function CrudOperations() {
  const [resource, setResource] = useState([]);
  const [selectedResource, setSelectResource] = useState();
  const [selectPage, setSelectPage] = useState("Read");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readId, setReadId] = useState("");
  const [readVId, setReadVId] = useState("");
  const [hId, setHid] = useState("");
  const [hvid, setHvid] = useState("");
  const [hlimit, setHLimit] = useState("");
  const [delId, setDelId] = useState("");
  const [createId, setCreateId] = useState("");
  const [createBody, setCreateBody] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [updateBody, setUpdateBody] = useState([]);
  const [validateContent, setValidateContent] = useState("");
  const [readResult, setReadResult] = useState();
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyResult, setHistoryResult] = useState([]);
  const [resultSet, setResultSet] = useState([]);
  const [ViewResult, setViewResult] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    const data = await getCall("metadata");
    console.log(data)
    if (!data) return;
    setResource(
      data.rest[0].resource.filter((x) => x.extension && x.extension.length > 0)
    );
    setSelectResource(data.rest[0].resource[0].type);
  };


  const getAllResoursedata = async function (e) {
    console.log(selectedResource)
    const result = await getCall(e.value)
    console.log(result)
    if (result) setResultSet(result.entry);
  }
  const OnclickSetEditData = async function (id) {
    getCall(id).then((x) => {
      if(x){
        setCreateId(x.id)
        setCreateBody(JSON.stringify(x))
        setUpdateId(x.id)
        setUpdateBody(JSON.stringify(x))
      }
      setViewResult(x);
    });
  }
 
  const onViewResultClick = function (id) {
    getCall(id).then((x) => {
      setViewResult(x);
    });
  };

  const onReadClick = function () {
    if (!readId) {
      alert("No Id specified");
      return;
    }
    let url =
      selectedResource?.value + "/" + readId + (readVId ? "/_history/" + readVId : "");
    getCall(url)
      .then((x) => {
        //setReadResult(x);
        setViewResult(x);
        setIsModalOpen(true);
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };

  const onResourceChange = function (e) {
    setSelectResource(e);
    getAllResoursedata(e)
  };

  const onHistoryClick = async (e) => {
    let url = e
    // let url = selectedResource;
    // if (url) {
    //   url += "/" + hId;
    // }
    url += "/_history";
    let opts = [];
    if (hvid) opts.push({ name: "since", value: hvid });
    if (hlimit) opts.push({ name: "_count", value: hlimit });
    url +=
      opts.length > 0
        ? "?" + opts.map((x) => x.name + "=" + x.value).join("&")
        : "";
    let result = await getCall(url);
    console.log("here", result);
    if (result) {
      setHistoryResult(result);
      console.log(historyResult)
      setIsHistoryModalOpen(true);
    }
  };
  const onDeleteClick = async (delId) => {
    if (!delId) return alert("ID requried");
    console.log(delId)
    const result = await deleteCall(delId);
    if (result) {
      toast.success('Resource deleted successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    else {
      toast.error('Resource delete failed', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // let sampleResponse = {
    //   resourceType: "OperationOutcome",
    //   text: {
    //     status: "generated",
    //     div: '<div xmlns="http://www.w3.org/1999/xhtml"><h1>Operation Outcome</h1><table border="0"><tr><td style="font-weight: bold;">INFORMATION</td><td>[]</td><td><pre>Successfully deleted 1 resource(s) in 5ms</pre></td>\n\t\t\t</tr>\n\t\t</table>\n\t</div>',
    //   },
    //   issue: [
    //     {
    //       severity: "information",
    //       code: "informational",
    //       diagnostics: "Successfully deleted 1 resource(s) in 5ms",
    //     },
    //   ],
    // };
  };
  const [createResult, setCreateResult] = useState(null);

  const onCreateClick = async (e) => {
    console.log(createId)
    let _createBody = JSON.parse(createBody);
    console.log(_createBody)
    if (_createBody && _createBody["resourceType"] && createId) {
      onUpdateClick(createBody);
      // let url = selectedResource?.value + "/" + _createBody.id;
      // console.log(url)
      // const result = await putCall(url, _createBody);
      // if (result) {

      //   toast.success('Resource Updated successfully', {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      //   setCreateResult(result?.data);
      // }
      // else {
      //   toast.error('Resource created failed', {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      //   setShowModal(false);
      // }
    } else if (_createBody && _createBody["resourceType"] && !createId) {
      let url = selectedResource?.value + "?_format=json&_pretty=true";
      console.log(url)
      const result = await postCall(url, _createBody);
      if (result) {
        setShowModal(true);
        toast.success('Resource created successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCreateResult(result?.data);
      }
      else {
        setShowModal(false);
        toast.error('Resource created failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    else {
      alert("Invalid");
    }
  };
  const onUpdateClick = async (e) => {
    if (!updateId) return alert("ID required");

    let _updateBody = JSON.parse(updateBody);
    _updateBody["id"] = updateId;
    if (_updateBody && _updateBody["resourceType"]) {
      let url =
        selectedResource.value + "/" + updateId + "?_format=json&_pretty=true";
      const result = await putCall(url, _updateBody);
    if (result) {

        toast.success('Resource Updated successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCreateResult(result?.data);
      }
      else {
        toast.error('Resource created failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowModal(false);
      }
    } else {
      alert("Invalid");
    }
  };
  const onValidateClick = async (e) => {
    if (!validateContent) {
      alert("No Id specified");
      return;
    }
    let _createBody = JSON.parse(validateContent);
    let url = selectedResource.value + "/$validate" + "?_format=json&_pretty=true";;
    const result = await postCall(url, _createBody).then((x) => {
      console.log(x)
      if (x.status == 200) {
        alert(x.status)
        setReadResult(x);
        console.log(x);
        setIsModalOpen(true);
      }

    });
  };

  return (
    <>
      <div className="m-2">
        <div className="row mb-3 mb-lg-4">
          <div className="col-12">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">
              CRUD Operations
            </div>
          </div>
          <div className="col-12">
            <div className="box001">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="mb-3 medium text-dark">
                      Select one Operation
                    </label>{" "}
                    <br></br>
                    <div className="d-inline-flex me-3">
                      <input
                        type="radio"
                        name="tab"
                        id="Red"
                        className="me-3"
                        value="Read"
                        checked={selectPage === "Read"}
                        onChange={(e) => setSelectPage(e.target.value)}
                      />
                      <span className="mt-11">Read</span>{" "}
                    </div>
                    <div className="d-inline-flex  me-3">
                      <input
                        type="radio"
                        className="me-3"
                        name="tab"
                        id="Red"
                        value="Create"
                        checked={selectPage === "Create"}
                        onChange={(e) => setSelectPage(e.target.value)}
                      />
                      <span className="mt-11">Create</span>
                    </div>
                    <div className="d-inline-flex">
                      <input
                        type="radio"
                        name="tab"
                        id="Red"
                        className="me-3"
                        value="Validate"
                        checked={selectPage === "Validate"}
                        onChange={(e) => setSelectPage(e.target.value)}
                      />
                      <span className="mt-11">Validate</span>
                    </div>
                    <div id="div1" className="mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <Select
                              className="basic-single w-100"
                              type="button"
                              placeholder={"Select Rescoures"}
                              classNamePrefix="select"
                              onChange={onResourceChange}
                              name="color"
                              options={resource?.map((data) => {
                                return {
                                  value: data.type,
                                  label:
                                    data.type +
                                    " (" +
                                    data.extension[0].valueDecimal +
                                    ")",
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      {selectPage === "Read" && (
                        <div className="row">
                          <p>
                            <span className="bold text-dark">Read</span> an
                            individual resource instance given its ID (and
                            optionally a version ID to retrieve a specific version
                            of that instance to vread that instance)
                          </p>
                          <div className="col-lg-2 col-md-3 form-group">
                            <button
                              className="w-100 btn btn-red"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewResultModal"
                              onClick={() => onReadClick()}
                            >
                              <img
                                src={bookIcon}
                                className="img-fluid"
                                alt="icon"
                              />{" "}
                              Read
                            </button>
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <div className="input-group form-group">
                              <span
                                className="input-group-text medium"
                                id="basic-addon1"
                              >
                                ID*
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={readId}
                                onChange={(e) => setReadId(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-5">
                            <div className="input-group form-group">
                              <span
                                className="input-group-text medium"
                                id="basic-addon1"
                              >
                                Version ID
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Add for vread"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={readVId}
                                onChange={(e) => setReadVId(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {selectPage === "Create" && (
                        <div id="div2">
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <span className="bold text-dark">Create</span> an
                                instance of the resource. Generally you do not
                                need to specify an ID but you may force the server
                                to use a specific ID by including one.
                              </p>
                              <div className="row">
                                <div className="col-lg-2 col-md-3 form-group">
                                  <button
                                    className="w-100 btn btn-red"
                                    data-bs-toggle="modal"
                                    data-bs-target="#CreateResultModal"
                                    onClick={() => onCreateClick()}
                                  >
                                    <img
                                      src={plusIcon}
                                      className="img-fluid"
                                      alt="icon"
                                    />{" "}
                                    Create
                                  </button>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                  <div className="input-group form-group">
                                    <span
                                      className="input-group-text medium"
                                      id="basic-addon1"
                                    >
                                      ID*
                                    </span>
                                    <input
                                      value={createId}
                                      onChange={(e) =>
                                        setCreateId(e.target.value)
                                      }
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                  <div className="input-group form-group">
                                    <span
                                      className="input-group-text medium"
                                      id="basic-addon1"
                                    >
                                      Contents*
                                    </span>
                                    <input
                                      value={createBody}
                                      onChange={(e) =>
                                        setCreateBody(e.target.value)
                                      }
                                      type="text"
                                      className="form-control"
                                      placeholder="Place resource body here"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {selectPage === "Validate" && (
                        <div id="div3">
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <span className="bold text-dark">Validate</span>{" "}
                                an instance of the resource to check whether it
                                would be acceptable for creating/updating, without
                                actually storing it on the server.
                              </p>
                              <div className="row">
                                <div className="col-lg-2 col-md-3 form-group">
                                  <button
                                    className="w-100 btn btn-red"
                                    onClick={() => onValidateClick()}
                                  >
                                    <img
                                      src={checkIcon}
                                      className="img-fluid"
                                      alt="icon"
                                    />{" "}
                                    Validate{" "}
                                  </button>
                                </div>
                                <div className="col-lg-4 col-md-5">
                                  <div className="input-group form-group">
                                    <span
                                      className="input-group-text medium"
                                      id="basic-addon1"
                                    >
                                      Contents*
                                    </span>
                                    <input
                                      value={validateContent}
                                      onChange={(e) =>
                                        setValidateContent(e.target.value)
                                      }
                                      type="text"
                                      className="form-control"
                                      placeholder="Place resource body here"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row align-items-center mb-3">
                        <div className="col-12 col-md-6">
                          <div className="ft-20 bold text-dark mb-2 mb-lg-0">
                            Searched Data
                          </div>
                        </div>
                        <div className="col-12 col-md-6 text-start text-md-end">
                          <button className=" btn btn-yellow">
                            <img
                              src={shareIcon}
                              className="img-fluid me-2"
                              alt="icon"
                            />{" "}
                            Export{" "}
                          </button>
                        </div>
                      </div>

                      <div className="row g-4">
                        {resultSet.length ? (
                          resultSet.map((c, i) => {
                            const id = `${c.resource.resourceType}/${c.resource.id}/_history/1`;
                            const resourceid = `${c.resource.resourceType}/${c.resource.id}`;

                            return (
                              <div className="col-md-6 col-lg-3">
                                <div className="box001">
                                  <p className="text-dark medium text-break mb-3">
                                    {`${c.resource.resourceType}/${c.resource.id}/_history/1`}
                                  </p>
                                  <p className="small">
                                    {new Date(c.resource.meta.lastUpdated).toLocaleString()}
                                  </p>
                                  <div className="mt-3 d-flex">
                                    <p
                                      className="me-3 text-primary h-op-8"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ViewResultModal"
                                      onClick={() => onViewResultClick(resourceid)}
                                    >
                                      <img
                                        src={viewIcon}
                                        className="img-fluid mm-2"
                                        alt="icon"
                                      />{" "}
                                      View Result
                                    </p>
                                    <p
                                      className="me-3 text-success h-op-8"
                                      data-bs-toggle="modal"
                                      data-bs-target="#narrativelModal"
                                      onClick={() => onViewResultClick(id)}
                                    >
                                      <img
                                        src={updateIcon}
                                        className="img-fluid mm-2"
                                        alt="icon"
                                      />{" "}
                                      Narrative
                                    </p>
                                    {
                                      /* <a
                                        className="text-success h-op-8"
                                        data-bs-toggle="popover"
                                        data-bs-trigger="focus"
                                        data-bs-html="true"
                                        title="Narrative Details"
                                        data-bs-content="<span className='bold'>ID:</span> ActivityDefinition/serum-dengue-virus-igm <br> 
                                               <span className='bold'>Status:</span> draft <br>
                                               <span className='bold'>Description:</span> Order Serum Dengue Virus IgM"
                                      >
                                        <img
                                          src={updateIcon}
                                          className="img-fluid mm-2"
                                          alt="icon"
                                        />{" "}
                                        Narrative
                                      </a> */
                                    }
                                  </div>
                                  <div className="mt-3 d-flex">
                                    <p
                                      className="me-3  btn btn-warning btn-small me-2 h-op-8"
                                      data-bs-toggle="modal"
                                      data-bs-target="#HistoryResultModal"
                                      onClick={() => onHistoryClick(resourceid)}
                                    >
                                      <img
                                        src={historyIcon}
                                        className="img-fluid mm-2"
                                        alt="icon"
                                      />{" "}

                                    </p>
                                    <p
                                      className="me-3  btn btn-danger btn-small  h-op-8"
                                      data-bs-toggle="modal"
                                      // data-bs-target="#narrativelModal"
                                      onClick={() => OnclickSetEditData(resourceid)}
                                    >
                                      <img
                                        src={pencilIcon}
                                        className="img-fluid mm-2"
                                        alt="icon"
                                      />{" "}

                                    </p>
                                    {
                                      <p
                                        className=" btn btn-red  btn-small  h-op-8"
                                        data-bs-toggle="popover"
                                        data-bs-trigger="focus"
                                        data-bs-html="true"
                                        title="Narrative Details"
                                        data-bs-content="<span className='bold'>ID:</span> ActivityDefinition/serum-dengue-virus-igm <br> 
                               <span className='bold'>Status:</span> draft <br>
                               <span className='bold'>Description:</span> Order Serum Dengue Virus IgM"
                                        onClick={() => onDeleteClick(resourceid)}

                                      >
                                        <ToastContainer
                                          position="top-right"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                          theme="light"
                                        />
                                        {/* Same as */}
                                        <ToastContainer />
                                        <img
                                          src={delteIcon}
                                          className="img-fluid mm-2"
                                          alt="icon"
                                        />{" "}
                                      </p>
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <h3 className="text-center">No Record Found</h3>
                        )}

                        {/* <div className="row mt-4">
            <div className="col-12 text-center bold text-theme ft-18">
              <p className="h-op-8">Load More...</p>
            </div>
          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ViewResultModal --> */}
      <div
        className="modal fade"
        id="ViewResultModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                View Result
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <article>
                    <div>
                      {ViewResult && <pre>{JSON.stringify(ViewResult, null, 2)}</pre>}
                      {!ViewResult && <div className="text-center">NO DATA FOUND</div>}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- NarrativelModal --> */}
      <div
        className="modal fade"
        id="narrativelModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Narrative Result
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <article>
                    <div>
                      {ViewResult?.text?.div && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ViewResult?.text?.div,
                          }}
                        ></div>
                      )}
                      {!ViewResult?.text?.div && (
                        <div className="text-center">NO DATA FOUND</div>
                      )}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- CreatelModal --> */}
      {showModal && (
        <div
          className="modal fade"
          id="CreateResultModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLabel">
                  Created Result
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <article>
                      <div>
                        {createResult && <pre>{JSON.stringify(createResult, null, 2)}</pre>}
                        {!createResult && <div className="text-center">NO DATA FOUND</div>}
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <!-- HistoryModal --> */}
      {isHistoryModalOpen && (
        <div
          className="modal fade"
          id="HistoryResultModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLabel">
                  History Result
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <article>
                      <div>
                        {historyResult && <pre>{JSON.stringify(historyResult, null, 2)}</pre>}
                        {!historyResult && <div className="text-center">NO DATA FOUND</div>}
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CrudOperations;
