import React from "react";

function Export() {
  return (
    <>
      {/* <!-- content --> */}
      <div className="content">
        {/* <!-- Dashboard --> */}
        <div className="row mb-3 mb-lg-4">
          <div className="col-md-5 col-lg-4">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">Sources</div>
            <p>Select source type</p>

            <div className="min-width-300">
              <div className="accordion" id="fhir11">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingOne"
                  >
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Flatfile
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                    data-bs-parent="#fhir11"
                  >
                    <div className="accordion-body p-0">
                      <ul className="list-unstyled conn-list mb-0">
                        <li>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#cncflatfileModal"
                          >
                            Create new connection
                          </a>
                        </li>
                        <li>
                          <div className="bold mb-2">Data Source 1</div>
                          <ul className="list-unstyled">
                            <li>Column 1</li>
                            <li>Column 2</li>
                            <li>Column 3</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingTwo"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      FHIR to FHIR
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                    data-bs-parent="#fhir11"
                  >
                    <div className="accordion-body p-0">
                      <ul className="list-unstyled conn-list mb-0">
                        <li>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#cncphirtophirModal"
                          >
                            Create new connection
                          </a>
                        </li>
                        <li>
                          <div className="bold">Data Source 1</div>
                        </li>
                        <li>
                          <div className="bold">Data Source 1</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Database
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                    data-bs-parent="#fhir11"
                  >
                    <div className="accordion-body p-0">
                      <ul className="list-unstyled conn-list mb-0">
                        <li>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#cncdatabaseModal"
                          >
                            Create new connection
                          </a>
                        </li>
                        <li>
                          <div className="bold mb-2">Data Source 1</div>
                          <ul className="list-unstyled">
                            <li>Column 1</li>
                            <li>Column 2</li>
                            <li>Column 3</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 col-lg-4">
            <div className="ft-20 bold text-dark mb-2 mb-lg-2">Target</div>
            <p>Select target type</p>

            <div className="min-width-300">
              <div className="accordion" id="fhir22">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-collapseTwoa"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwoa"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseTwoa"
                    >
                      FHIR to FHIR
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwoa"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-collapseTwoa"
                    data-bs-parent="#fhir22"
                  >
                    <div className="accordion-body p-0">
                      <ul className="list-unstyled conn-list mb-0">
                        <li>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#cntphirtophirModal"
                          >
                            Create new connection
                          </a>
                        </li>
                        <li>
                          <div className="bold">Data Source 1</div>
                        </li>
                        <li>
                          <div className="bold">Data Source 1</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-collapseThree2"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree2"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree2"
                    >
                      Database
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree2"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-collapseThree2"
                    data-bs-parent="#fhir22"
                  >
                    <div className="accordion-body p-0">
                      <ul className="list-unstyled conn-list mb-0">
                        <li>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#cntdatabaseModal"
                          >
                            Create new connection
                          </a>
                        </li>
                        <li>
                          <div className="bold mb-2">Data Source 1</div>
                          <ul className="list-unstyled">
                            <li>Column 1</li>
                            <li>Column 2</li>
                            <li>Column 3</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- flat file source Modal --> */}
      <div
        className="modal fade"
        id="cncflatfileModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Create Connection
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2">Name</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        name=""
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-2">Upload your file</label>
                      <div className="drag-area">
                        <div className="icon">
                          <i className="fas fa-images"></i>
                        </div>

                        <span className="header">Drag & Drop</span>
                        <span className="header">
                          or <span className="button">browse</span>
                        </span>
                        <input type="file" hidden />
                        <span className="support">
                          Supports: JPEG, JPG, PNG
                        </span>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button className=" btn btn-red">
                        Create connection
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Phir to phir source Modal --> */}
      <div
        className="modal fade"
        id="cncphirtophirModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Create Source
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2">Configuration</label>
                      <input
                        type="text"
                        placeholder="Data Source Name "
                        className="form-control"
                        name=""
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-2">Connection type</label>
                      <select
                        className="form-select g-fc form-control"
                        id="myselection"
                      >
                        <option>Select Option</option>
                        <option value="directconnectio">
                          Direct Connection
                        </option>
                        <option value="awsauth">
                          Aws Auth -Secret Arn THEN
                        </option>
                        <option value="awsauth">Azure Auth - Secret Key</option>
                        <option value="awsauth">Google Auth - Key</option>
                      </select>
                    </div>
                  </div>
                  <div id="showdirectconnectio" className="myDiv">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="mb-2">Host</label>
                          <input
                            type="text"
                            placeholder="Host"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="mb-2">Port</label>
                          <input
                            type="text"
                            placeholder="Port"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">User</label>
                          <input
                            type="text"
                            placeholder="User"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">Password</label>
                          <input
                            type="text"
                            placeholder="Password"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="mb-2">Schema</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="showawsauth" className="myDiv">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="mb-2">Database</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-2">Secret Key</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label className="container1 d-inline-block me-3">
                      Use SSL
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container1 d-inline-block me-3">
                      Use SSH
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <div className="col-12">
                    <div className="text-center mt-3">
                      <button className=" btn btn-red mx-2">Save</button>
                      <button className=" btn btn-danger mx-2">Test</button>
                      <button className=" btn btn-yellow mx-2">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- database source Modal --> */}
      <div
        className="modal fade"
        id="cncdatabaseModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Create Source
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2">Configuration</label>
                      <input
                        type="text"
                        placeholder="Data Source Name "
                        className="form-control"
                        name=""
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-2">Connection type</label>
                      <select
                        className="form-select g-fc form-control"
                        id="myselection"
                      >
                        <option>Select Option</option>
                        <option value="directconnectio">
                          Direct Connection
                        </option>
                        <option value="awsauth">
                          Aws Auth -Secret Arn THEN
                        </option>
                        <option value="awsauth">Azure Auth - Secret Key</option>
                        <option value="awsauth">Google Auth - Key</option>
                      </select>
                    </div>
                  </div>
                  <div id="showdirectconnectio" className="myDiv">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="mb-2">Host</label>
                          <input
                            type="text"
                            placeholder="Host"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="mb-2">Port</label>
                          <input
                            type="text"
                            placeholder="Port"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">User</label>
                          <input
                            type="text"
                            placeholder="User"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">Password</label>
                          <input
                            type="text"
                            placeholder="Password"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="mb-2">Schema</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="showawsauth" className="myDiv">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="mb-2">Database</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-2">Secret Key</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label className="container1 d-inline-block me-3">
                      Use SSL
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container1 d-inline-block me-3">
                      Use SSH
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <div className="col-12">
                    <div className="text-center mt-3">
                      <button className=" btn btn-red mx-2">Save</button>
                      <button className=" btn btn-danger mx-2">Test</button>
                      <button className=" btn btn-yellow mx-2">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!--phir to phir target Modal --> */}
      <div
        className="modal fade"
        id="cntphirtophirModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Create Target
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2">Configuration</label>
                      <input
                        type="text"
                        placeholder="Data Source Name "
                        className="form-control"
                        name=""
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-2">Connection type</label>
                      <select
                        className="form-select g-fc form-control"
                        id="myselection"
                      >
                        <option>Select Option</option>
                        <option value="directconnectio">
                          Direct Connection
                        </option>
                        <option value="awsauth">
                          Aws Auth -Secret Arn THEN
                        </option>
                        <option value="awsauth">Azure Auth - Secret Key</option>
                        <option value="awsauth">Google Auth - Key</option>
                      </select>
                    </div>
                  </div>
                  <div id="showdirectconnectio" className="myDiv">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="mb-2">Host</label>
                          <input
                            type="text"
                            placeholder="Host"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="mb-2">Port</label>
                          <input
                            type="text"
                            placeholder="Port"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">User</label>
                          <input
                            type="text"
                            placeholder="User"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">Password</label>
                          <input
                            type="text"
                            placeholder="Password"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="mb-2">Schema</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="showawsauth" className="myDiv">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="mb-2">Database</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-2">Secret Key</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label className="container1 d-inline-block me-3">
                      Use SSL
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container1 d-inline-block me-3">
                      Use SSH
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <div className="col-12">
                    <div className="text-center mt-3">
                      <button className=" btn btn-red mx-2">Save</button>
                      <button className=" btn btn-danger mx-2">Test</button>
                      <button className=" btn btn-yellow mx-2">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!--database target Modal --> */}
      <div
        className="modal fade"
        id="cntdatabaseModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                Create Target
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2">Configuration</label>
                      <input
                        type="text"
                        placeholder="Data Source Name "
                        className="form-control"
                        name=""
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-2">Connection type</label>
                      <select
                        className="form-select g-fc form-control"
                        id="myselection"
                      >
                        <option>Select Option</option>
                        <option value="directconnectio">
                          Direct Connection
                        </option>
                        <option value="awsauth">
                          Aws Auth -Secret Arn THEN
                        </option>
                        <option value="awsauth">Azure Auth - Secret Key</option>
                        <option value="awsauth">Google Auth - Key</option>
                      </select>
                    </div>
                  </div>
                  <div id="showdirectconnectio" className="myDiv">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="mb-2">Host</label>
                          <input
                            type="text"
                            placeholder="Host"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="mb-2">Port</label>
                          <input
                            type="text"
                            placeholder="Port"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">User</label>
                          <input
                            type="text"
                            placeholder="User"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2">Password</label>
                          <input
                            type="text"
                            placeholder="Password"
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="mb-2">Schema</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="showawsauth" className="myDiv">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="mb-2">Database</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-2">Secret Key</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <label className="container1 d-inline-block me-3">
                      Use SSL
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container1 d-inline-block me-3">
                      Use SSH
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <div className="col-12">
                    <div className="text-center mt-3">
                      <button className=" btn btn-red mx-2">Save</button>
                      <button className=" btn btn-danger mx-2">Test</button>
                      <button className=" btn btn-yellow mx-2">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Export;
